import { Box, Button, Card, CardContent, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ImageContext, ImageDispatchContext } from '../../context/ImageContext';
import { API } from 'aws-amplify';
import { PurchaseButtonCredits10 } from './purchaseButtonCredits_10';
import { PurchaseButtonCredits50 } from './purchaseButtonCredits_50';
import { PurchaseButtonCredits200 } from './purchaseButtonCredits_200';
import { useNavigate } from 'react-router-dom';
import PricingPlans from '../tool/components/pricingPlans';

const AccountPage = () => {
    const { username, stripeEmail, subscriptionStatus, plan, periodEnd, periodDaysLeft, originCountry } = useContext(ImageContext);
    const dispatch = useContext(ImageDispatchContext);
    const [requestsLeftMsg, setRequestsLeftMsg] = useState("..");

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const navigate = useNavigate();

    useEffect(() => {
        setRequestsLeft();
    }, [plan]);

    const setRequestsLeft = async () => {
        let creditsLeft = await getCredits();
        setRequestsLeftMsg(creditsLeft.toString());
        dispatch({ type: "CHANGE_CREDITS_LEFT", creditsLeft: creditsLeft });
    }

    const getCredits = async () => {
        //get credits from DB or else return 0
        const apiName = 'imagetocaptioncreditsapi';
        const path = '/credits/' + username + `?origin=${originCountry}`;

        try {
            const response = await API.get(apiName, path, {});
            if (response === "error") {
                console.log("Error: increment captions in DB failed");
                return 0;
            } else {
                return response.credits;
            }
        } catch (error) {
            console.log(error);
            return 0;
        }
    }

    const openCalendly = () => {
        window.open("https://calendly.com/imagetocaption-ai", "_blank");
    }

    const openCanny = () => {
        window.open("https://forms.gle/b1D3fXFm7oZbBmg28", "_blank");
    }

    const openMail = () => {
        window.location.href = "mailto:info@imagetocaption.ai";
    }

    const stripeManageSubscriptionUrl = "https://billing.stripe.com/p/login/8wM3fH5an7am0ve4gg" + "?prefilled_email=" + stripeEmail;

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card className="account-details-card">
                        <CardContent>
                            <Typography variant="h5">Account Details</Typography>
                            <Typography variant="body1">Username: {username}</Typography>
                            <Typography variant="body1">Status: {subscriptionStatus}</Typography>
                            <Typography variant="body1">Plan: {plan}</Typography>

                            {plan !== 'Custom Plan' && (
                                <>
                                    <Typography variant="body1">Period end: {periodEnd}</Typography>
                                    <Typography variant="body1">Days left: {periodDaysLeft}</Typography>
                                    <Typography variant="body1" mb={2}>Credits left: {requestsLeftMsg}</Typography>
                                </>)}

                            {subscriptionStatus === "active" || subscriptionStatus === "trialing" || subscriptionStatus === "cancelled" ?
                                <Button variant="contained" color="primary" href={stripeManageSubscriptionUrl}>Manage Subscription</Button>
                                :
                                null}

                            {subscriptionStatus === "active" || subscriptionStatus === "trialing" ?
                                <Box mt={2}>
                                    {plan === 'Basic Plan' && <PurchaseButtonCredits10 />}
                                    {plan === 'Plus Plan' && <PurchaseButtonCredits50 />}
                                    {plan === 'Elite Plan' && <PurchaseButtonCredits200 />}
                                    {plan !== 'Elite Plan' && <Button sx={{ mt: 2 }} variant='text' color='secondary' onClick={() => navigate('/app-sumo')}>Stack Codes (AppSumo)</Button>}
                                </Box>
                                : null}
                        </CardContent>
                    </Card>
                </Grid>
                {subscriptionStatus !== "active" && subscriptionStatus !== "trialing" && (
                    <Grid item xs={12} className="pricing-plans-card">
                        <Box justifyContent="center">
                            <PricingPlans />
                        </Box>
                    </Grid>
                )}
                <Grid item xs={12} className="caption-history-card">
                    <HistoryCard />
                </Grid>
                <Grid item xs={12}>
                    {subscriptionStatus === "active"
                        ? (
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">

                                <Typography variant='body1' mb={2}>Do you have feedback or ideas how we can improve? Let us know:</Typography>
                                {/* <Stack direction="row" spacing={2} mb={2}>
                                <Chip variant="outlined" label="Book call" onClick={openCalendly} sx={{ mb: 2 }} />
                                <Chip variant="outlined" label="Request feature" onClick={openCanny} sx={{ mb: 2 }} />
                            </Stack> */}
                                <Box>
                                    {isMobile ? (
                                        <Box>
                                            <iframe
                                                src="https://docs.google.com/forms/d/e/1FAIpQLSfuDB2ugeYg-LB87WnB7JYebl9PinyH5maTkcEVgdTb3oKknw/viewform?embedded=true"
                                                width="380"
                                                height="500"
                                                frameBorder={0}
                                                marginHeight={0}
                                                marginWidth={0}
                                                title="Google Form">
                                                Loading…
                                            </iframe>
                                        </Box>
                                    ) :
                                        (
                                            <Box>
                                                <iframe
                                                    src="https://docs.google.com/forms/d/e/1FAIpQLSfuDB2ugeYg-LB87WnB7JYebl9PinyH5maTkcEVgdTb3oKknw/viewform?embedded=true"
                                                    width="640"
                                                    height="1000"
                                                    frameBorder={0}
                                                    marginHeight={0}
                                                    marginWidth={0}
                                                    title="Google Form">
                                                    Loading…
                                                </iframe>
                                            </Box>
                                        )}
                                </Box>
                            </Box>
                        ) : (
                            <></>
                        )}
                </Grid>
            </Grid>
        </div>
    );
};

const HistoryCard = () => {
    const [captionHistory, setCaptionHistory] = useState<{ caption: string, date: string }[]>([]);
    const { username } = useContext(ImageContext);


    useEffect(() => {
        getCaption();
    }, []);

    const getCaption = async () => {
        let apiName = 'imagetocaptionapipro';
        let myInit = {}
        let path = '/history';

        API.get(apiName, path + `/${username}`, myInit).then((response) => {
            if (response !== "error") {
                setCaptionHistory(response);
            }
        }).catch((error) => {
            console.log(error);

        });
    }

    return (
        <Card className="caption-history-card">
            <CardContent>
                <Typography variant="h5" pb={2}>Caption History</Typography>
                {captionHistory.map((item, index) => (
                    <div key={index}>
                        <Typography variant="body1" pb={0.5}>Date: {item.date}</Typography>
                        <TextField value={item.caption} fullWidth label="Caption" variant="outlined" multiline sx={{mb:2}}/>
                    </div>
                ))}
            </CardContent>
        </Card>
    );
}


export default AccountPage;
