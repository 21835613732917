import { useContext, useEffect, useState } from "react";
import { Box, Button, Stack, Checkbox, Typography, MenuItem, Select, InputLabel, FormControl, Tooltip, IconButton, LinearProgress } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material"
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { ImageContext, ImageDispatchContext } from "../../../context/ImageContext";
import ImagePreview from "./image-preview";
import { v4 as uuidv4 } from 'uuid';
import Resizer from "react-image-file-resizer";
import { useCookies } from "react-cookie";
import { API } from 'aws-amplify';

const UploadImage = () => {
    const { bucketRegion, plan, preset, username, bucketName, imageName, creditsLeft, responseCaption, videoAnalysisLoading } = useContext(ImageContext);
    const dispatch = useContext(ImageDispatchContext);

    const [uploadDisabled, setUploadDisabled] = useState(false);
    const [cookies] = useCookies(['sbmCntM']);

    const [videoDuration, setVideoDuration] = useState(0);
    const [fileSize, setFileSize] = useState(0);

    const [payedUser, setPayedUser] = useState(false);

    useEffect(() => {
        console.log(plan);
        if (!cookies.sbmCntM) {
        } else {
            if (!plan) {
                if (cookies.sbmCntM >= 20) {
                    setUploadDisabled(true);
                }
            }
        }
        if (plan) {
            setPayedUser(true);
        }
    }
        , [cookies, plan]);

    useEffect(() => {

        if (creditsLeft < 1) {
            setUploadDisabled(true);
        }
    }, [creditsLeft, responseCaption]);

    const region = "eu-central-1";
    const client = new S3Client({
        region,
        credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region }),
            identityPoolId: 'eu-central-1:32ef5408-2b2d-46bc-949b-e1b9469217d2',
        }),
    });

    const handleFileInput = async (e) => {
        const files = e.target.files; // Get the selected files
        let imageCount = 0;
        let videoCount = 0;
        let imgUploadSizeLimit = checkFileSizeLimit("image");
        let vidUploadSizeLimit = checkFileSizeLimit("video");
        dispatch({ type: "RESET" });
        setVideoOptionsHidden(true);

        // Count the number of images and videos
        for (let i = 0; i < files.length; i++) {
            if (files[i].type.startsWith('image/')) {
                imageCount++;
            } else if (files[i].type === 'video/mp4') {
                videoCount++;
            }
        }

        // Check the conditions: multiple images allowed, only one video allowed, not both
        if (videoCount > 1 || (imageCount > 0 && videoCount > 0)) {
            alert('You can upload multiple images or a single video but not both.');
            // Clear the input if the condition is not met
            e.target.value = '';
            return;
        } else if (imageCount > 6) {
            alert('You can not upload more than 6 images. Please select the most relevant images.');
            // Clear the input if the condition is not met
            e.target.value = '';
        } else if (imageCount > 1) {
            let bucket = "image-to-caption-uploadstore";
            let url = "";

            for (let index = 0; index < imageCount; index++) {
                let file = e.target.files[index];
                let imgName = uuidv4();

                if (file.size > imgUploadSizeLimit) {
                    alert("File '" + file.name + "' is too big! Must be smaller than " + imgUploadSizeLimit / 1024 / 1024 + "MB");
                } else {
                    try {
                        let cmpImg = await compressImage(file);
                        const compressedBlob = await urlToBlob(cmpImg);
                        await handleUploadImage(compressedBlob, imgName, bucket);
                        url += "https://" + bucket + ".s3." + bucketRegion + ".amazonaws.com/" + imgName + ";";
                        dispatch({ type: "CHANGE_URL", imageUrl: url });

                    } catch (error) {
                        console.log(error);
                        alert("Error while uploading image!");
                    }
                }
            }

            await describeImage(url);

        } else {
            let imgName = uuidv4();
            let file = e.target.files[0];
            let fileType = file.type;

            if (fileType.startsWith("image/")) {
                if (file.size > imgUploadSizeLimit) {
                    alert("File is too big! Must be smaller than " + imgUploadSizeLimit / 1024 / 1024 + "MB");
                } else {
                    try {
                        let cmpImg = await compressImage(file);
                        const compressedBlob = await urlToBlob(cmpImg);
                        let bucket = "image-to-caption-uploadstore";
                        let url = "https://" + bucket + ".s3." + bucketRegion + ".amazonaws.com/" + imgName;

                        await handleUploadImage(compressedBlob, imgName, bucket);
                        dispatch({ type: "CHANGE_URL", imageUrl: url });
                        dispatch({ type: "CHANGE_FILENAME", imageName: imgName });

                        await describeImage(url);

                    } catch (error) {
                        console.log(error);
                        alert("Error while uploading image!");
                    }
                }
            } else if (fileType.startsWith("video/")) {
                let vidDuration = 0;
                await getVideoDuration(file).then(duration => {
                    vidDuration = Math.floor(duration);
                }).catch(error => {
                    console.error(error);
                    alert("There has been an error. Please re-upload the file.");
                });
                if (vidUploadSizeLimit === 0) {
                    alert("Free plan does not support video upload. Please upgrade your plan.");
                } else {
                    if (file.size > vidUploadSizeLimit) {
                        alert("File is too big! Must be smaller than " + vidUploadSizeLimit / 1024 / 1024 + "MB");
                    }
                    if (vidDuration > checkVideoDurationLimit()) {
                        alert("Video is too long! Upload a video shorter than " + checkVideoDurationLimit() + " seconds or upgrade your plan.");
                    } else if (vidDuration === 0) {
                        alert("There has been an error. Please re-upload the file.");
                    } else {
                        let vidName = imgName + ".mp4";
                        let bucket = "video-to-caption-uploadstore";

                        await handleUploadVideo(file, vidName, bucket, vidDuration, file.size);
                        dispatch({ type: "CHANGE_BUCKETNAME", bucketName: bucket });
                        dispatch({ type: "CHANGE_FILENAME", imageName: vidName });
                        setVideoDuration(vidDuration);
                        setFileSize(file.size);
                    }
                }
            } else {
                // The file is neither an image nor a video
                alert("Invalid file type! Please upload an image or a video in MP4 format.");
            }
        }
    }



    const getVideoDuration = async (file) => {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = function () {
                window.URL.revokeObjectURL(video.src);
                resolve(video.duration);
            };

            video.onerror = function () {
                reject("Error in loading video file");
            };

            video.src = URL.createObjectURL(file);
        });
    };

    const checkFileSizeLimit = (type) => {
        let uploadSize = 0;
        if (type === "image") {
            uploadSize = 20971520;//20MB
        } else if (type === "video") {
            if (plan === "Basic Plan" || plan === "Personal Plan") {
                uploadSize = 524288000;//500MB
            } else if (plan === "Plus Plan" || plan === "Business Plan" || plan === "Elite Plan" || plan === "Custom Plan") {
                uploadSize = 2147483648;//2GB
            } else {
                uploadSize = 0;
            }
        }
        return uploadSize;
    }

    const checkVideoDurationLimit = () => {
        let videoDuration = 0;
        if (plan === "Personal Plan" || plan === "Basic Plan") {
            videoDuration = 60;
        } else {
            videoDuration = 180;
        }
        return videoDuration;
    }

    const compressImage = (image) =>
        new Promise((resolve) => {
            const size = payedUser ? 1000 : 600;
            Resizer.imageFileResizer(
                image,
                size,
                size,
                "JPEG",
                90,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });


    // Helper function to convert data URI to Blob
    const urlToBlob = (url) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                resolve(xhr.response);
            };
            xhr.onerror = function () {
                reject(new Error('Failed to convert URL to Blob'));
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        });
    };

    const handleUploadImage = async (image, name, bucket) => {
        const command = new PutObjectCommand({
            Bucket: bucket,
            Key: name,
            Body: image,
        });
        dispatch({ type: "UPLOAD", imageUploading: true, imageUploaded: false })

        try {
            await client.send(command);
            if (preset === "long") {
                dispatch({ type: "CHANGE_CREDITS_COST", creditsCost: 2 });
            } else {
                dispatch({ type: "CHANGE_CREDITS_COST", creditsCost: 1 });
            }
            dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: false });
            dispatch({ type: "UPLOAD", imageUploading: false, imageUploaded: true });

        } catch (err) {
            console.error(err);
            alert("Error while uploading image!");
        }
    };

    const describeImage = async (url) => {
        dispatch({ type: "CHANGE_IMAGE_ANALYZING", imageAnalyzing: true });
        dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: true });
        let apiName = 'imagetocaptionapipro';
        if (plan === "Custom Plan") {
            switch (username) {
                case "socialminds":
                    apiName = 'imagetocaptionAPIbears';
                    break;
                case "socialmindstest":
                    apiName = 'imagetocaptionAPIcoldaid';
                    break;
                case "bearswithbenefits":
                    apiName = 'imagetocaptionAPIbears';
                    break;
                case "ollo":
                    apiName = 'imagetocaptionAPIollo';
                    break;
                case "coolaid":
                    apiName = 'imagetocaptionAPIcoldaid';
                    break;
                default:
                    apiName = 'imagetocaptionAPIbears';
                    break;
            }
        }

        let myInit = {
            body: {
                url: url
            }
        }
        const path = '/describeimage';


        API.post(apiName, path, myInit).then((response) => {
            console.log(response);
            dispatch({ type: "CHANGE_IMAGE_DESCRIPTION", imageDescription: response })
            dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: false });
            dispatch({ type: "CHANGE_IMAGE_ANALYZING", imageAnalyzing: false });
        }).catch((error) => {
            dispatch({ type: "CHANGE_IMAGE_ANALYZING", imageAnalyzing: false });
            console.log(error);
        });

    }

    const handleUploadVideo = async (image, name, bucket) => {
        const command = new PutObjectCommand({
            Bucket: bucket,
            Key: name,
            Body: image,
        });
        dispatch({ type: "UPLOAD", imageUploading: true, imageUploaded: false })

        try {
            await client.send(command);
            dispatch({ type: "CHANGE_URL", imageUrl: "https://" + bucket + ".s3." + bucketRegion + ".amazonaws.com/" + name });
            console.log("upload done");
            setVideoOptionsHidden(false);
            setAnalysisDisabled(false);
            dispatch({ type: "UPLOAD", imageUploading: false, imageUploaded: true });

        } catch (err) {
            console.error(err);
            dispatch({ type: "UPLOAD", imageUploading: false, imageUploaded: true });
            setAnalysisDisabled(true);
            alert("Error while uploading image!");
        }
    };

    const startAnalysis = async () => {
        setAnalysisDisabled(true);
        dispatch({ type: "CHANGE_VIDEO_ANALYSIS_LOADING", videoAnalysisLoading: true });
        if (useAudio && useVideo) {
            await startMediaConvert(bucketName, imageName, videoDuration, fileSize);
            startTranscription(imageName, videoDuration, fileSize);
        } else if (useVideo) {
            startMediaConvert(bucketName, imageName, videoDuration, fileSize);
        }
    }

    const startMediaConvert = async (bucketName, name, videoDuration, fileSize) => {
        let apiName = 'videotocaptionapi';

        const path = '/mediaconvert';
        const myInit = {
            body: {
                bucketname: bucketName,
                filename: name,
                duration: videoDuration,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        API.post(apiName, path, myInit).then(() => {
            describeFrames(name, fileSize, videoDuration);
        }).catch((error) => {
            alert("Error while analyzing video! Please try again")
            setAnalysisDisabled(false);
            console.log(error);
        });
    };

    const startTranscription = async (name, videoDuration, fileSize) => {
        let apiName = 'videotocaptionapi';

        let newFileName = name.split(".mp4")[0];
        newFileName = newFileName + "_video.mp4";
        console.log(newFileName);

        const path = '/transcribe';
        const myInit = {
            body: {
                bucketname: "video-to-caption-thumbnails",
                filename: newFileName,
                language: speechLanguage,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
        await delay(calculateDelay(videoDuration, fileSize));

        API.post(apiName, path, myInit).then(() => {
            console.log("Transcription started");
        }).catch((error) => {
            alert("Error while analyzing audio! Please try again")
            setAnalysisDisabled(false);
            console.log(error);
        });
    };

    const describeFrames = async (imageName, fileSize, videoDuration) => {
        let apiName = 'videotocaptionapi';

        const path = '/describe';
        const myInit = {
            body: {
                filename: imageName,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
        await delay(calculateDelay(videoDuration, fileSize));

        try {
            const response = await retryApiCall(apiName, path, myInit);

            dispatch({ type: "CHANGE_CREDITS_COST", creditsCost: calculateCreditCost(videoDuration) });

            dispatch({ type: "CHANGE_VIDEO_DESCRIPTION", videoDescription: response });

            if (useAudio) {
                //enable caption button after delay to allow transcription to finish
                setTimeout(() => {
                    dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: false });
                }, (calculateDelay(videoDuration, fileSize) + 5000));
            } else {
                dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: false });
            }
        } catch (error) {
            console.log(error);
            alert("Error while analyzing video! Please try again")
        }
    };

    const retryApiCall = async (apiName, path, myInit, retries = 3) => {
        for (let i = 0; i < retries; i++) {
            try {
                const response = await API.post(apiName, path, myInit);
                return response;
            } catch (error) {
                if (i < retries - 1) {
                    await new Promise(resolve => setTimeout(resolve, 5000));
                } else {
                    throw error;
                }
            }
        }
    };

    function calculateDelay(duration, fileSize) {
        const fileSizeMB = fileSize / (1024 * 1024); // Convert file size to MB
        const fileSizeMBPerSec = fileSizeMB / duration; // Calculate MB per second

        let delay = 5000; // Minimum delay

        if (duration > 10) {
            if (fileSizeMBPerSec > 10) {
                delay += 1500 * duration;
            } else if (fileSizeMBPerSec >= 1 && fileSizeMBPerSec <= 10) {
                delay += fileSizeMBPerSec * 150 * duration; // Adjust this factor as needed
            } else if (fileSizeMBPerSec < 1) {
                delay += 150 * duration;
            }
        }

        return delay;
    }

    function calculateCreditCost(duration) {
        let cost = 3;
        if (duration > 30) {
            cost += Math.ceil((duration - 30) / 30);
        }
        if (useAudio) {
            cost += 1;
        }
        if (preset === "long") {
            cost += 1;
        }
        return cost;
    }

    const [useAudio, setUseAudio] = useState(false);
    const [useVideo, setUseVideo] = useState(true);

    const [speechLanguage, setSpeechLanguage] = useState("en");

    const [analysisDisabled, setAnalysisDisabled] = useState(true);

    const [videoOptionsHidden, setVideoOptionsHidden] = useState(true);

    const handleAudioChange = (event) => {
        setUseAudio(event.target.checked);
        dispatch({ type: "CHANGE_INCLUDE_TRANSCRIPTION", includeTranscription: event.target.checked })
    };

    const handleLanguageChange = (event) => {
        setSpeechLanguage(event.target.value);
    };

    const [tooltipOpen, setTooltipopen] = useState(false);

    const handleTooltipClose = () => {
        setTooltipopen(false);
    };

    const handleTooltipOpen = () => {
        setTooltipopen(true);
    };

    const handleClick = () => {
        setTooltipopen(!tooltipOpen);
    };

    return (
        <Box mb={4}>
            <Stack direction="column" spacing={4}>
                <Box justifyContent="center" display="flex">
                    <Button variant="contained" component="label" disabled={uploadDisabled} className="upload-image-container">
                        Upload<br /> (up to 6 Images or 1 Video)
                        <input hidden accept="image/*,video/mp4" type="file" multiple onChange={handleFileInput} />
                    </Button>
                </Box>
                <Box justifyContent="center" display="flex">
                    <ImagePreview />
                </Box>
            </Stack>

            {!videoOptionsHidden && (
                <Stack direction="column" alignItems="center" >
                    <Stack direction={"row"} justifyContent="center" >
                        <Box alignItems="center" display="flex" width={"280px"}>
                            <Checkbox
                                checked={useVideo}
                            />
                            <Typography>Use visual analysis</Typography>
                        </Box>
                    </Stack>
                    <Stack direction={"row"} justifyContent="center">
                        <Box alignItems="center" display="flex" width={"280px"}>
                            <Checkbox
                                checked={useAudio}
                                onChange={handleAudioChange}
                            />
                            <Typography>Use speech analysis</Typography>
                            <Tooltip
                                placement="top"
                                arrow
                                title={"This is only necessary if the video contains speech. Make sure to select the correct spoken language. It will increase the cost of the caption by 1 credit."}
                                open={tooltipOpen}
                                onMouseEnter={handleTooltipOpen}
                                onMouseLeave={handleTooltipClose}
                            >
                                <IconButton onClick={handleClick}>
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        </Box>

                    </Stack>


                    {useAudio && (
                        <FormControl sx={{ mb: 2, mt: 2, minWidth: 200 }}>
                            <InputLabel>Video speech language</InputLabel>
                            <Select
                                value={speechLanguage}
                                onChange={handleLanguageChange}
                                label="Video speech language"
                            >
                                <MenuItem value="en">🇬🇧 English</MenuItem>
                                <MenuItem value="de">🇩🇪 German</MenuItem>
                                <MenuItem value="fr">🇫🇷 French</MenuItem>
                                <MenuItem value="es">🇪🇸 Spanish</MenuItem>
                                <MenuItem value="ar">🇦🇪 Arabic</MenuItem>
                                <MenuItem value="ch">🇨🇳 Chinese</MenuItem>
                                <MenuItem value="ko">🇰🇷 Korean</MenuItem>
                                <MenuItem value="ja">🇯🇵 Japanese</MenuItem>
                                <MenuItem value="ru">🇷🇺 Russian</MenuItem>
                                <MenuItem value="pr">🇵🇹 Portugese</MenuItem>
                                <MenuItem value="pl">🇵🇱 Polish</MenuItem>
                                <MenuItem value="bu">🇧🇬 Bulgarian</MenuItem>
                                <MenuItem value="cr">🇭🇷 Croatian</MenuItem>
                                <MenuItem value="cz">🇨🇿 Czech</MenuItem>
                                <MenuItem value="da">🇩🇰 Danish</MenuItem>
                                <MenuItem value="du">🇳🇱 Dutch</MenuItem>
                                <MenuItem value="fi">🇫🇮 Finnish</MenuItem>
                                <MenuItem value="sw">🇸🇪 Swedish</MenuItem>
                                <MenuItem value="gr">🇬🇷 Greek</MenuItem>
                                <MenuItem value="hu">🇭🇺 Hungarian</MenuItem>
                                <MenuItem value="ro">🇷🇴 Romanian</MenuItem>
                                <MenuItem value="tu">🇹🇷 Turkish</MenuItem>
                                <MenuItem value="hi">🇮🇳 Hindi</MenuItem>
                                <MenuItem value="te">🇮🇳 Telugu</MenuItem>
                                <MenuItem value="ma">🇮🇳 Marathi</MenuItem>
                                <MenuItem value="ta">🇮🇳 Tamil</MenuItem>
                                <MenuItem value="ka">🇮🇳 Kannada</MenuItem>
                                <MenuItem value="od">🇮🇳 Odia</MenuItem>
                            </Select>
                        </FormControl>
                    )}

                    <Button onClick={startAnalysis} variant="contained" disabled={analysisDisabled}>Start video analysis</Button>

                    {videoAnalysisLoading && (
                        <>
                            <Box width={'80%'} mt={2} mb={2} alignSelf={'center'}>
                                <LinearProgress />
                            </Box>
                            <Typography variant="caption" align="center">Analyzing video... <br />You can continue with filling out the parameters below</Typography>
                        </>)}

                </Stack>
            )}

        </Box>
    );
};

export default UploadImage;