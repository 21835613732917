import { useContext } from 'react';
import { Alert, Stack } from '@mui/material';
import { Box } from '@mui/material';
import { ImageContext, ImageDispatchContext } from '../../../context/ImageContext';

export const NotificationBar = () => {
    const { notificationBarHidden } = useContext(ImageContext);
    const dispatch = useContext(ImageDispatchContext);

    const style = {
        position: 'sticky',
        top: 0,
        zIndex: 10000,
        width: '100%',
        padding: '10px',
        boxSizing: 'border-box',
    };

    const isMobile = window.innerWidth <= 600; // Adjust the breakpoint value as needed

    const handleClose = () => {
        dispatch({ type: 'CHANGE_NOTIFICATIONBAR_HIDDEN', notificationBarHidden: true });
    };

    return (
        <Box sx={style}>
            <Box display={notificationBarHidden == false ? "inline-block" : "none"} sx={{ width: '100%' }}>
                <Alert onClose={handleClose} severity="info" sx={{ maxWidth: '100%', fontSize: isMobile ? '12px' : 'inherit' }}>
                    <strong>Black Friday Deal:</strong> Get 50% off all yearly plans with code <strong>BLACK50</strong>. Only valid till Saturday.
                </Alert>
            </Box>
        </Box>
    );
};