import { Box, Button, CircularProgress, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { ImageContext } from "../../../context/ImageContext";
import { useContext, useEffect, useState } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { PurchaseButtonCredits10 } from "../../account/purchaseButtonCredits_10";
import { PurchaseButtonCredits200 } from "../../account/purchaseButtonCredits_200";
import { PurchaseButtonCredits50 } from "../../account/purchaseButtonCredits_50";
import { ThumbDown, ThumbUp } from "@mui/icons-material";
import { API } from "aws-amplify";

export const Content = () => {
    const { responseCaption, responseLoading, plan, subscriptionStatus, username, customhashtags, hashtags } = useContext(ImageContext);
    const [captionValue, setCaptionValue] = useState<string>("");
    const [editDisabled, setEditDisabled] = useState<boolean>(true);
    const [feedbackDisabled, setFeedbackDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (responseCaption === "") {
            setEditDisabled(true)
            setFeedbackDisabled(true);
        } else {
            setEditDisabled(false)
            setFeedbackDisabled(false);
        }

        let newCaptionValue = responseCaption;

        if (newCaptionValue.startsWith('"') && newCaptionValue.endsWith('"')) {
            newCaptionValue = responseCaption.slice(1, -1);
        }

        if (customhashtags !== undefined && hashtags === true) {
            newCaptionValue += ' ' + customhashtags;
        }

        if (plan === "Basic Plan" || plan === "Plus Plan" || plan === "Elite Plan" || plan === "Custom Plan") {
            setCaptionValue(newCaptionValue);
        } else {
            if (responseCaption !== "" && responseCaption !== "Loading...") {
                setCaptionValue(newCaptionValue + " #imagetocaption @imagetocaptionai");
            } else {
                setCaptionValue(newCaptionValue);
            }
        }
    }, [responseCaption, responseLoading]);

    const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newCaptionValue = event.target.value;
        
        // Remove custom hashtags if they exist at the end of the caption
        if (customhashtags && hashtags) {
            const hashtagIndex = newCaptionValue.lastIndexOf(customhashtags);
            if (hashtagIndex !== -1 && hashtagIndex === newCaptionValue.length - customhashtags.length) {
                newCaptionValue = newCaptionValue.slice(0, hashtagIndex).trim();
            }
        }

        // Add custom hashtags only if they're not already present
        if (customhashtags && hashtags && !newCaptionValue.includes(customhashtags)) {
            newCaptionValue += ' ' + customhashtags;
        }

        setCaptionValue(newCaptionValue);
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(captionValue);
    }

    const giveFeedback = (feedbackValue) => {
        const apiName = 'imagetocaptionapipro';
        const path = '/feedback';

        console.log(responseCaption);
        const formattedCaption = responseCaption.replace(/\n/g, '\n');
        console.log(formattedCaption);

        let myInit = {
            body: {
                username: username,
                caption: responseCaption,
                feedback: feedbackValue
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        API.post(apiName, path, myInit).then((response) => {
            if (response === "error") {
                console.log("Error: decrement captions in DB failed")
            } else {
                setFeedbackDisabled(true);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const [tooltipOpenGood, setTooltipopenGood] = useState(false);
    const [tooltipOpenBad, setTooltipopenBad] = useState(false);

    const handleTooltipCloseGood = () => {
        setTooltipopenGood(false);
    };

    const handleTooltipOpenGood = () => {
        setTooltipopenGood(true);
    };

    const handleClick = () => {
        setTooltipopenGood(!tooltipOpenGood);
    };

    const handleToolTipCloseBad = () => {
        setTooltipopenBad(false);
    };

    const handleTooltipOpenBad = () => {
        setTooltipopenBad(true);
    };


    return (
        <Box textAlign="center" >
            <Typography variant='h4' mt={4} mb={2}>Caption: </Typography>
            <TextField disabled={editDisabled} value={captionValue} onChange={changeValue} fullWidth label="Caption" variant="outlined" multiline />
            <Tooltip
                arrow
                title={"Was this a good caption?"}
                open={tooltipOpenGood}
                onMouseEnter={handleTooltipOpenGood}
                onMouseLeave={handleTooltipCloseGood}
            >
                <IconButton disabled={feedbackDisabled} color='success' onClick={() => giveFeedback("good")}><ThumbUp /></IconButton>
            </Tooltip>
            <Tooltip
                arrow
                title={"Was this a bad caption?"}
                open={tooltipOpenBad}
                onMouseEnter={handleTooltipOpenBad}
                onMouseLeave={handleToolTipCloseBad}
            >
                <IconButton disabled={feedbackDisabled} color='error' onClick={() => giveFeedback("bad")}><ThumbDown /></IconButton>
            </Tooltip>
            <Box mt={2}>
                {responseLoading === true ? <CircularProgress /> : <></>}
            </Box>
            {responseCaption !== "" &&
                <Button onClick={handleCopy}>
                    <ContentCopyIcon />
                    <Typography>Copy</Typography>
                </Button>
            }
            {subscriptionStatus === "active" && plan !== "Custom Plan" ? <Upselling /> : <></>}
        </Box>
    )
}



function Upselling() {
    const { plan, stripeEmail } = useContext(ImageContext);

    const stripeManageSubscriptionUrl = "https://billing.stripe.com/p/login/8wM3fH5an7am0ve4gg" + "?prefilled_email=" + stripeEmail;


    return (
        <Box display={"flex"} justifyContent="center" alignItems="center">
            <Box style={{ padding: '16px', backgroundColor: '#f5f5f5', width: "600px" }} borderRadius={6} mt={4}>
                <Typography variant="subtitle1" style={{ color: '#757575' }}>
                    Don't want to run out of credits?
                </Typography>
                <Typography variant="body2" style={{ color: '#757575' }}>
                </Typography>

                <Box mt={2}>
                    {plan === 'Basic Plan' && <PurchaseButtonCredits10 />}
                    {plan === 'Plus Plan' && <PurchaseButtonCredits50 />}
                    {plan === 'Elite Plan' && <PurchaseButtonCredits200 />}
                </Box>

                {plan !== 'Elite Plan' && <>
                    <Box>
                        <Typography variant="body2" style={{ color: '#757575' }} pt={2}>
                            Or upgrade your plan to get more credits every month and save money!
                        </Typography>

                        <Box mt={1} mb={2}>
                            {plan === 'Basic Plan' && <>
                                <Typography variant="body2" style={{ color: '#757575' }}>
                                    Currently: $0,5 / credit
                                </Typography>
                                <Typography variant="body2" style={{ color: '#757575' }}>
                                    Plus Plan: $0,3 / credit
                                </Typography>
                                <Typography variant="body2" style={{ color: '#757575' }}>
                                    Elite Plan: $0,25 / credit
                                </Typography>
                            </>}
                            {plan === 'Plus Plan' && <>
                                <Typography variant="body2" style={{ color: '#757575' }}>
                                    Currently: $0,3 / credit
                                </Typography>
                                <Typography variant="body2" style={{ color: '#757575' }}>
                                    Elite Plan: $0,25 / credit
                                </Typography>
                            </>}
                        </Box>
                    </Box>
                    <Button variant="contained" color="secondary" href={stripeManageSubscriptionUrl}>Upgrade Plan</Button>
                </>}

            </Box>
        </Box>
    );
}

export default Upselling;
