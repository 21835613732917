import { CheckBox, UploadFile, Timelapse, FormatLineSpacing, SupportAgent, School } from "@mui/icons-material";
import { Grid, Paper, Typography, Divider, Box, Stack, useTheme, Chip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ImageContext } from "../../../../context/ImageContext";
import { PurchaseButtonPlus } from "../../../account/purchaseButtonPlus";

const PlusPlan = () => {
  const theme = useTheme();
  const { paymentPeriod } = useContext(ImageContext);

  const [billed, setBilled] = useState("Billed monthly");

  const plan = {
    name: 'Plus',
    features: [
      '100 credits/month',
      'Max. video size: 2GB',
      'Max. video length: 3 minutes',
      'Max. 100 characters',
      'standard support',
      'access to knowledge base',
      '+ every additional 50 credits for $15'
    ],
  };

  useEffect(() => {
    if (paymentPeriod === "monthly") {
      setBilled('$29.99/month');
    } else {
      setBilled('$299.99/year');
    }

  }, [paymentPeriod]);

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Paper sx={{ p: 1.75 }} >
        <Chip label="Recommended" color="success" variant="outlined" sx={{ mt: '-60px', backgroundColor: 'white' }} />

        <Typography variant="h3" component="h3">
          {plan.name}
        </Typography>
        <Typography variant="h5">
          {billed}
        </Typography>

        <Typography variant="caption" textAlign={'center'}>
          {plan.features[6]}
        </Typography>
        
        <Box pt={2} pb={2}>
          <Divider />
        </Box>

        <Stack direction="row" spacing={1}>
          <CheckBox style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[0]}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <UploadFile style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[1]}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Timelapse style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[2]}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <School style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[5]}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}  pb={2}>
          <SupportAgent style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[4]}
          </Typography>
        </Stack>

        <PurchaseButtonPlus />

      </Paper>
    </Grid>
  );
}

export default PlusPlan;