import React, { useState, useEffect, useRef, useContext } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Checkbox,
    FormControlLabel,
    Button,
    Box,
    Stack,
    Modal,
    Fade,
    IconButton,
    useMediaQuery,
    useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate, useLocation } from 'react-router-dom';
import { API } from 'aws-amplify';
import { ImageContext } from '../../context/ImageContext';

const OnboardingWizard = () => {
    const { username } = useContext(ImageContext);
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [steps, setSteps] = useState([
        { label: 'Account Details', completed: false, route: '/account', subSteps: 3 },
        { label: 'Brandvoice set-up', completed: false, route: '/settings' },
        { label: 'General Settings', completed: false, route: '/settings#generalsettings' },
        { label: 'Caption creation', completed: false, route: '/', subSteps: 6 },
    ]);

    const [completed, setCompleted] = useState(0);
    const [activeStep, setActiveStep] = useState(null);
    const [activeSubStep, setActiveSubStep] = useState(0);
    const [explanationPosition, setExplanationPosition] = useState({ top: 0, left: 0 });
    const [isExpanded, setIsExpanded] = useState(true);
    const explanationRef = useRef(null);

    const [wizardStepsComplete, setWizardStepsComplete] = useState({
        step1: false,
        step2: false,
        step3: false,
        step4: false
    });
    const [wizardDone, setWizardDone] = useState(null);


    useEffect(() => {
        if (username) {
            fetchWizardStatus();
        }
    }, [username]);

    useEffect(() => {
        if (activeStep !== null && explanationRef.current) {
            let targetCard;

            if (activeStep === 0) {
                switch (activeSubStep) {
                    case 0:
                        targetCard = document.querySelector('.account-details-card');
                        break;
                    case 1:
                        targetCard = document.querySelector('.pricing-plans-card');
                        if (targetCard) {
                            targetCard.scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }
                        break;
                    case 2:
                        targetCard = document.querySelector('.caption-history-card');
                        if (targetCard) {
                            targetCard.scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }
                        break;
                    default:
                        targetCard = null;
                }
            } else if (activeStep === 1) {
                targetCard = document.querySelector('.brandvoice-card');
            } else if (activeStep === 2) {
                targetCard = document.querySelector('.general-settings-card');
            } else if (activeStep === 3) {
                switch (activeSubStep) {
                    case 0:
                        targetCard = document.querySelector('.upload-image-container');
                        break;
                    case 1:
                        targetCard = document.querySelector('.caption-length-container');
                        break;
                    case 2:
                        targetCard = document.querySelector('.caption-language-container');
                        break;
                    case 3:
                        targetCard = document.querySelector('.brandvoice-selection-container');
                        break;
                    case 4:
                        targetCard = document.querySelector('.custom-prompt-container');
                        break;
                    case 5:
                        targetCard = document.querySelector('.submit-button-container');
                        // Scroll to the submit button container
                        if (targetCard) {
                            setTimeout(() => {
                                targetCard.scrollIntoView({ behavior: 'smooth', block: 'start' });
                            }, 100); // Timeout to ensure DOM is ready
                        }
                        break;
                    default:
                        targetCard = null;
                }
            }

            if (targetCard) {
                const targetRect = targetCard.getBoundingClientRect();
                const explanationRect = explanationRef.current.getBoundingClientRect();
                const offset = 20; // Offset for positioning

                let topPosition, leftPosition;

                if (isMobile) {
                    topPosition = Math.min(
                        targetRect.bottom + window.scrollY + offset,
                        window.innerHeight + window.scrollY - explanationRect.height - offset
                    );
                    leftPosition = Math.max(
                        offset,
                        Math.min(
                            targetRect.left + window.scrollX,
                            window.innerWidth + window.scrollX - explanationRect.width - offset
                        )
                    );
                } else {
                    topPosition = Math.max(
                        window.scrollY + offset,
                        Math.min(
                            targetRect.top + window.scrollY,
                            window.innerHeight + window.scrollY - explanationRect.height - offset
                        )
                    );
                    leftPosition = Math.min(
                        targetRect.right + window.scrollX + offset,
                        window.innerWidth + window.scrollX - explanationRect.width - offset
                    );

                    // If the explanation card would be below the viewport, position it at the bottom of the screen
                    if (topPosition + explanationRect.height > window.innerHeight + window.scrollY) {
                        topPosition = window.innerHeight + window.scrollY - explanationRect.height - offset;
                    }
                }



                // Ensure the card is always within the viewport for sub steps
                if (activeStep === 0 && activeSubStep === 2) {
                    // Calculate the maximum allowed top position
                    const maxTopPosition = window.scrollY + window.innerHeight - explanationRect.height - offset - 50;
                    console.log("MAX TOP POSITION: " + maxTopPosition);
                    // Adjust if below viewport
                    if (topPosition > maxTopPosition) {
                        console.log("Card was below viewport, adjusting");
                        topPosition = maxTopPosition - window.innerHeight + 300;
                    }

                    console.log("Final top: " + topPosition);
                }
                setExplanationPosition({ top: topPosition, left: leftPosition });

            }
        }
    }, [activeStep, activeSubStep, isMobile, location.pathname]);

    const fetchWizardStatus = async () => {
        console.log(username);
        try {
            const response = await API.get('settingsapi', '/brandvoice/object/' + username, {});
            console.log(response);
            if (response.wizardstepscomplete) {
                const parsedSteps = JSON.parse(response.wizardstepscomplete);
                setWizardStepsComplete(parsedSteps);
                let completedObjects = Object.values(parsedSteps).filter(Boolean).length;
                setCompleted(completedObjects);
            }
            setWizardDone(response.wizarddone || false);
        } catch (error) {
            console.error('Error fetching wizard status:', error);
            setWizardDone(false); // Set to false in case of error
        }
    };

    const updateWizardStatus = async (newStepsComplete, isDone = false) => {
        try {
            await API.put('settingsapi', '/brandvoice', {
                body: {
                    username: username,
                    wizardstepscomplete: JSON.stringify(newStepsComplete),
                    wizarddone: isDone
                }
            });
        } catch (error) {
            console.error('Error updating wizard status:', error);
        }
    };

    const handleStepComplete = (stepIndex) => {
        const newStepsComplete = { ...wizardStepsComplete, [`step${stepIndex + 1}`]: true };
        setWizardStepsComplete(newStepsComplete);
        updateWizardStatus(newStepsComplete);

        if (Object.values(newStepsComplete).every(step => step)) {
            setWizardDone(true);
            updateWizardStatus(newStepsComplete, true);
        }
    };

    const handleWizardClose = () => {
        setWizardDone(true);
        updateWizardStatus(wizardStepsComplete, true);
    };

    const handleStepToggle = (index) => {
        // Allow toggling if the step is completed or if it's the next available step
        if (wizardStepsComplete[`step${index + 1}`] || index === 0 || wizardStepsComplete[`step${index}`]) {
            setActiveStep(index);
            setActiveSubStep(0);
            navigate(steps[index].route);
            if (isMobile) {
                setIsExpanded(false);
            }

            // Scroll to top for all steps except "General Settings"
            if (index !== 2) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }
    };

    const handleSubStepComplete = () => {
        const currentStep = steps[activeStep];
        if (currentStep.subSteps && activeSubStep < currentStep.subSteps - 1) {
            setActiveSubStep(activeSubStep + 1);
        } else {
            const newSteps = [...steps];
            newSteps[activeStep].completed = true;
            setSteps(newSteps);
            setCompleted(newSteps.filter(step => step.completed).length);
            setActiveStep(null);

            handleStepComplete(activeStep);

            // Expand the checklist on mobile after completing a full step
            if (isMobile) {
                setIsExpanded(true);
            }
        }
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const getStepContent = (step, subStep) => {
        if (step === 0) {
            if (subStep === 0) {
                return "Here you can find all the information regarding your subscription.<br /> - your current plan<br /> - credits left<br /> - reset date for your credits<br /> - upgrade or cancel your plan";
            } else if (subStep === 1) {
                return "Here you upgrade your plan whenever you are ready.";
            } else if (subStep === 2) {
                return "This is your caption history. You can review and manage your past captions here.";
            }
        } else if (step === 1) {
            if (subStep === 0) {
                return "Here you can set up your brand voice. This will help the AI to understand your brand and write captions accordingly.";
            }
        } else if (step === 2) {
            if (subStep === 0) {
                return "Here you can set your default settings for your captions.";
            }
        } else if (step === 3) {
            if (subStep === 0) {
                return "First upload an image or video to get started.";
            } else if (subStep === 1) {
                return "Select the Pre-Set of your caption. Just choose your platform. Longer captions are optimized for platforms like LinkedIn and not recommended for Instagram or TikTok.";
            } else if (subStep === 2) {
                return "Select the language of your caption.";
            } else if (subStep === 3) {
                return "Activate your brand voice. We recommend to always use it unless you create captions that are not related to your brand.";
            } else if (subStep === 4) {
                return "Add a custom prompt if you want to add any more information that are not covered by the other parameters or brand voice.";
            } else if (subStep === 5) {
                return "That's it! You can now generate your caption. Once created you can copy it and use it on your social media.<br/> - Tag use with @imagetocaptionai<br/> - use the hashtag #imagetocaptionai";
            }
        }
        return "Unknown step";
    };

    return (
        <>
            {wizardDone === false && (
                <Card
                    sx={{
                        position: 'fixed',
                        bottom: 20,
                        right: 20,
                        width: 350,
                        maxWidth: '90vw',
                        boxShadow: 3,
                        borderRadius: 2,
                        zIndex: 1000
                    }}
                >
                    <CardContent>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                            <Typography variant="h6" component="div">
                                Get the most out of your Digital Marketing Assistant
                            </Typography>
                            <Box>
                                <IconButton size="small" onClick={handleWizardClose}>
                                    <CloseIcon />
                                </IconButton>
                                <IconButton onClick={toggleExpand} size="small">
                                    {isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                                </IconButton>
                            </Box>
                        </Box>
                        {isExpanded && (
                            <>
                                <Typography variant="body2" color="text.secondary" mb={2}>
                                    Boost Your Productivity in Just 4 Steps
                                </Typography>
                                <Typography variant="body2" color="text.secondary" mb={2}>
                                    {completed} / 4 completed
                                </Typography>
                                {/* Checkbox steps */}
                                <Stack spacing={1}>
                                    {steps.map((step, index) => (
                                        <Card key={index} variant="outlined" sx={{ width: '100%' }}>
                                            <CardContent sx={{ padding: 1, '&:last-child': { paddingBottom: 1 } }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={wizardStepsComplete[`step${index + 1}`]}
                                                            onChange={() => handleStepToggle(index)}
                                                            color="primary"
                                                            disabled={index !== 0 && !wizardStepsComplete[`step${index}`]} />
                                                    }
                                                    label={step.label}
                                                    sx={{ margin: 0, width: '100%', justifyContent: 'flex-start' }}
                                                />
                                            </CardContent>
                                        </Card>
                                    ))}
                                </Stack>
                                <Box mt={2} display="flex" justifyContent="space-between">
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        href="https://www.youtube.com/watch?v=wbFV8Oh9Uos&t=1s"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Tutorial Video
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        href="https://joshuaheller.notion.site/Getting-started-d0fd7f69d0f14cc68ee3191f873fcc47?pvs=74"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Help Center
                                    </Button>
                                </Box>
                            </>
                        )}
                    </CardContent>
                </Card>
            )}

            <Modal
                open={activeStep !== null}
                onClose={() => setActiveStep(null)}
                closeAfterTransition
            >
                <Fade in={activeStep !== null}>
                    <Box
                        ref={explanationRef}
                        sx={{
                            position: 'absolute',
                            top: explanationPosition.top,
                            left: explanationPosition.left,
                            zIndex: 1001,
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'relative',
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    width: 0,
                                    height: 0,
                                    borderStyle: 'solid',
                                    ...(isMobile
                                        ? {
                                            bottom: '100%',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            borderWidth: '0 10px 10px 10px',
                                            borderColor: 'transparent transparent #fff transparent',
                                        }
                                        : {
                                            top: '10px',
                                            right: '100%',
                                            borderWidth: '10px 10px 10px 0',
                                            borderColor: 'transparent #fff transparent transparent',
                                        }),
                                },
                            }}
                        >
                            <Card
                                sx={{
                                    width: isMobile ? '90vw' : 400,
                                    position: 'relative',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <CardContent>
                                    <Typography variant={isMobile ? "subtitle1" : "h6"} gutterBottom>
                                        {activeStep !== null && steps[activeStep].label}
                                    </Typography>
                                    <Typography
                                        variant={isMobile ? "body2" : "body1"}
                                        paragraph
                                        dangerouslySetInnerHTML={{ __html: activeStep !== null ? getStepContent(activeStep, activeSubStep) : '' }}
                                    />
                                    <Button
                                        variant="contained"
                                        size={isMobile ? "small" : "medium"}
                                        onClick={handleSubStepComplete}
                                    >
                                        {activeSubStep < (steps[activeStep]?.subSteps || 1) - 1 ? "Next Step" : "Complete Step"}
                                    </Button>
                                </CardContent>
                            </Card>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default OnboardingWizard;
