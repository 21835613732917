import { useContext, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { ImageContext, ImageDispatchContext } from "../../../context/ImageContext";
import { API } from 'aws-amplify';
import { PurchaseButtonCredits10 } from "../../account/purchaseButtonCredits_10";
import { PurchaseButtonCredits200 } from "../../account/purchaseButtonCredits_200";
import { PurchaseButtonCredits50 } from "../../account/purchaseButtonCredits_50";

const SubmitButton = () => {
    const { imageUrl, imageName, bucketName, getCaptionButtonDisabled, imageUploaded, responseCaption, plan, username, videoDescription, imageDescription, imageUploading, subscriptionStatus, includeTranscription, videoAnalysisLoading, imageAnalyzing, responseLoading } = useContext(ImageContext);
    const { language, brandvoice, theme, setting, targetaudience, mood, formofadress, custom, emojis, hashtags, cta, ctaType, preset, creativity, length, creditsCost, creditsLeft, numberofhashtags, originCountry } = useContext(ImageContext);
    const dispatch = useContext(ImageDispatchContext);

    const [tryAgaintime, setTryAgaintime] = useState("");

    useEffect(() => {
        checkRequestsLeft();
    }, [responseCaption]);

    const checkRequestsLeft = async () => {
        if ((await getCredits()) < 1) {
            dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: true });

            {
                subscriptionStatus === "active" || subscriptionStatus === "trialing" ? (
                    setTryAgaintime("Your account request limit has been reached. Top up your credits now in the account section or wait for your new credits next month.")
                ) : (
                    setTryAgaintime("Please subscribe to generate captions.")
                )
            }

        }
    }


    const getCaption = async () => {
        dispatch({ type: "CHANGE_LOADING", responseLoading: true });
        dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: true });
        let payedUser = false;
        let apiName;
        let myInit = {}
        let path = '/caption';

        if (plan) {
            payedUser = true;
        }
        let hashtagsNumber = 0;

        switch (plan) {
            case "Custom Plan":
                switch (username) {
                    case "socialminds":
                        apiName = 'imagetocaptionAPIbears';
                        break;
                    case "socialmindstest":
                        apiName = 'imagetocaptionAPIcoldaid';
                        break;
                    case "bearswithbenefits":
                        apiName = 'imagetocaptionAPIbears';
                        break;
                    case "ollo":
                        apiName = 'imagetocaptionAPIollo';
                        break;
                    case "coolaid":
                        apiName = 'imagetocaptionAPIcoldaid';
                        break;
                    default:
                        apiName = 'imagetocaptionAPIbears';
                        break;
                }
                (hashtags ? hashtagsNumber = numberofhashtags : hashtagsNumber = 0)
                myInit = {
                    body: {
                        description: imageDescription,
                        numberOfHashtags: hashtagsNumber,
                        customPrompt: custom,
                        length: length,
                    }
                }
                if (imageUrl.endsWith(".mp4")) {
                    path = '/videocaption';
                    myInit = {
                        body: {
                            name: imageName,
                            description: videoDescription,
                            numberOfHashtags: hashtagsNumber,
                            customPrompt: custom,
                            length: length,
                            audio: includeTranscription
                        }
                    }
                }
                break;
            default:
                hashtagsNumber = 0;
                (hashtags ? hashtagsNumber = numberofhashtags : hashtagsNumber = 0)
                apiName = 'imagetocaptionapipro';
                myInit = {
                    body: {
                        language: language,
                        theme: theme,
                        setting: setting,
                        mood: mood,
                        custom: custom,
                        emojis: emojis,
                        hashtags: hashtags,
                        numberOfHashtags: hashtagsNumber,
                        cta: cta,
                        ctaType: ctaType,
                        preset: preset,
                        length: length,
                        user: username,
                        description: imageDescription,
                        brandvoice: brandvoice,
                        country: originCountry,
                        plan: plan
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
                if (imageUrl.endsWith(".mp4")) {
                    apiName = 'videotocaptionapi';
                    myInit = {
                        body: {
                            language: language,
                            theme: theme,
                            setting: setting,
                            targetaudience: targetaudience,
                            mood: mood,
                            custom: custom,
                            emojis: emojis,
                            hashtags: hashtags,
                            numberOfHashtags: hashtagsNumber,
                            cta: cta,
                            ctaType: ctaType,
                            length: length,
                            preset: preset,
                            user: username,
                            description: videoDescription,
                            audio: includeTranscription,
                            brandvoice: brandvoice,
                            country: originCountry,
                            plan: plan
                        },
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    };
                }
                break;
        }


        API.post(apiName, path, myInit).then((response) => {
            if (response === "error") {
                dispatch({ type: "CHANGE_CAPTION", responseCaption: "Error. " + "Please try again." });
                dispatch({ type: "CHANGE_LOADING", responseLoading: false });
                dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: false });
            } else {
                dispatch({ type: "CHANGE_CAPTION", responseCaption: response });
                dispatch({ type: "CHANGE_LOADING", responseLoading: false });
                dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: false });
                decrementCredits(creditsCost);
                dispatch({ type: "CHANGE_CREDITS_COST", creditsCost: 1 });
            }
        }).catch((error) => {
            console.log(error);
            dispatch({ type: "CHANGE_CAPTION", responseCaption: "Error. " + "Please try again." });
            dispatch({ type: "CHANGE_LOADING", responseLoading: false });
            dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: false });
        });
    }

    const getCredits = async () => {
        //get credits from DB
        const apiName = 'imagetocaptioncreditsapi';
        const path = '/credits/' + username;

        try {
            const response = await API.get(apiName, path, {});
            if (response === "error") {
                console.log("Error: increment captions in DB failed");
                return 0;
            } else {
                return response.credits;
            }
        } catch (error) {
            console.log(error);
            return 0;
        }
    }

    const decrementCredits = async (creditsCost) => {
        const apiName = 'imagetocaptioncreditsapi';
        const path = '/credits/' + username + '/decrement-credits/' + creditsCost;

        API.put(apiName, path, {}).then((response) => {
            if (response === "error") {
                console.log("Error: decrement captions in DB failed")
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const generateButtonText = () => {
        if (getCaptionButtonDisabled == true) {
            if (imageAnalyzing === true) {
                return "Analyzing image... (can take up to 10s)";
            }
            if (responseLoading) {
                return "Waiting for AI to generate caption...";
            }
            return "Upload visuals above to Generate Caption";
        } else {
            if (imageUploaded === true && responseCaption !== "") {
                return "Regenerate Caption" + " (Costs " + creditsCost + " credits)";
            } else if (imageUploading === true) {

                return "Uploading File...";
            } else {
                return "Generate Caption" + " (Costs " + creditsCost + " credits)";
            }
        }
    }

    useEffect(() => {
        generateButtonText();
    }, [imageUploaded, imageAnalyzing, responseCaption]);

    useEffect(() => {
        if (getCaptionButtonDisabled == false) {
            dispatch({ type: "CHANGE_VIDEO_ANALYSIS_LOADING", videoAnalysisLoading: false })
        }
    }, [getCaptionButtonDisabled, videoAnalysisLoading])

    return (
        <Stack direction="column" spacing={4}>
            <Box className="submit-button-container">
                <Button disabled={getCaptionButtonDisabled} variant="contained" component="label" onClick={getCaption} >
                    {videoAnalysisLoading ? <Box alignItems={"center"}><CircularProgress size={20} sx={{ mr: 1 }} />analyzing video (can take up to 30s)...</Box> : generateButtonText()}
                </Button>
            </Box>

            {plan !== "Custom Plan" && (
                <>
                    <Typography variant="caption" mt={1} textAlign={"center"}>You have {creditsLeft} credits left</Typography>

                    {subscriptionStatus === "active" && creditsLeft <= 20 ?
                        <Box mt={2}>
                            {plan === 'Basic Plan' && <PurchaseButtonCredits10 />}
                            {plan === 'Plus Plan' && <PurchaseButtonCredits50 />}
                            {plan === 'Elite Plan' && <PurchaseButtonCredits200 />}
                        </Box>
                        : null}
                </>
            )}

            <Box display={getCaptionButtonDisabled == false ? "none" : "inline-block"}>
                <Typography variant="body1" color="red" textAlign={"center"}>{tryAgaintime}</Typography>
            </Box>

        </Stack>
    );
}

export default SubmitButton;