import { Content } from "./components/content";
import SubmitButton from "./components/SubmitButton";
import UploadImage from "./components/UploadImage";
import CaptionOptions from "./components/captionOptions";
import { useContext, useEffect, useState } from "react";
import { ImageContext, ImageDispatchContext } from "../../context/ImageContext";
import PricingPlans from "./components/pricingPlans";
import { Typography } from "@mui/material";
import { API } from "aws-amplify";

const Tool = () => {
    const { subscriptionStatus, username, responseCaption, plan, originCountry, creditsLeft, preset } = useContext(ImageContext);
    const dispatch = useContext(ImageDispatchContext);
    const [requestsLeftMsg, setRequestsLeftMsg] = useState("...");

    useEffect(() => {
        setRequestsLeft();
    }, [responseCaption]);

    useEffect(() => {
        getSettings();
    }, [])

    const setRequestsLeft = async () => {
        let creditsLeft = await getCredits();
        setRequestsLeftMsg(creditsLeft.toString());
        dispatch({ type: "CHANGE_CREDITS_LEFT", creditsLeft: creditsLeft });
    }

    const getCredits = async () => {
        //get credits from DB or else return 0
        const apiName = 'imagetocaptioncreditsapi';
        const path = '/credits/' + username + `?origin=${originCountry}`;

        try {
            const response = await API.get(apiName, path, {});
            if (response === "error") {
                console.log("Error: increment captions in DB failed");
                return 0;
            } else {
                return response.credits;
            }
        } catch (error) {
            console.log(error);
            return 0;
        }
    }

    const getSettings = async () => {
        const apiName = 'settingsapi';
        const path = '/brandvoice/object/' + username;

        try {
            const response = await API.get(apiName, path, {});
            if (response === "error") {
                console.log("Error: get settings from DB failed");
            } else {
                if (response.brandname || response.description || response.tonality) {
                    dispatch({ type: "CHANGE_BRANDVOICE_EXIST", brandvoiceExist: true })
                } else {
                    dispatch({ type: "CHANGE_BRANDVOICE_EXIST", brandvoiceExist: false })
                }

                if (response.alwaysuse) {
                    dispatch({ type: "CHANGE_BRANDVOICE", brandvoice: "use" });
                } else {
                    dispatch({ type: "CHANGE_BRANDVOICE", brandvoice: "none" });
                }

                if (response.preset !== undefined) {
                    dispatch({ type: 'CHANGE_PRESET', preset: response.preset });
                }
                if (response.emojis !== undefined) {
                    dispatch({ type: 'CHANGE_EMOJIS', emojis: response.emojis });
                }
                if (response.cta !== undefined) {
                    dispatch({ type: 'CHANGE_CTA', cta: response.cta });
                }
                if (response.hashtags !== undefined) {
                    dispatch({ type: 'CHANGE_HASHTAGS', hashtags: response.hashtags });
                }
                if (response.language !== undefined) {
                    dispatch({ type: 'CHANGE_LANGUAGE', language: response.language });
                }
                if (response.length !== undefined) {
                    dispatch({ type: 'CHANGE_LENGTH', length: response.length });
                }
                if (response.numberofhashtags !== undefined) {
                    dispatch({ type: 'CHANGE_NUMBEROFHASHTAGS', numberofhashtags: response.numberofhashtags });
                }
                if (response.defaulthashtags !== undefined) {
                    dispatch({ type: 'CHANGE_CUSTOMHASHTAGS', customhashtags: response.defaulthashtags });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            {subscriptionStatus === "cancelled" ? (
                <>
                    <Typography variant="h4" pb={2}>We miss you! <br></br>Resubscribe now with code COMEBACK30 for 30% off any plan🤩</Typography>
                    <PricingPlans />
                </>
            ) : subscriptionStatus !== "active" && subscriptionStatus !== "trialing" ? (
                <>
                    {creditsLeft >= 1 ?
                        (
                            <>
                                <UploadImage />
                                <CaptionOptions />
                                <SubmitButton />
                                <Content />
                            </>
                        ) :
                        (
                            <></>
                        )}

                    <Typography variant="h5" component="h1" pb={2} pt={2}>You have {creditsLeft} credits left for this month. Upgrade now to keep using imagetocaption.ai</Typography>
                    <PricingPlans />
                </>
            ) : (
                <div>
                    <UploadImage />
                    <CaptionOptions />
                    <SubmitButton />
                    <Content />
                </div>)}

        </>
    );
}

export default Tool;