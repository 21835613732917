import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Stack, TextField } from "@mui/material";
import { API } from 'aws-amplify';
import { ImageContext } from "../../context/ImageContext";

const BrandVoiceCard = () => {
    const [name, setName] = useState("")
    const [description, setDescription] = useState("");
    const [tonality, setTonality] = useState("");
    const { username, subscriptionStatus } = useContext(ImageContext);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [retrieveDisabled, setRetrieveDisabled] = useState(true);
    const [alwaysUseBrandVoice, setAlwaysUseBrandVoice] = useState(true);
    const [brandvoiceLoading, setBrandvoiceLoading] = useState(false);
    const [urlLoading, setUrlLoading] = useState(false);
    const [wordCountDescription, setWordCountDescription] = useState(0);
    const [wordCountName, setWordCountName] = useState(0)
    const [wordCountTonality, setWordCountTonality] = useState(0)
    const [url, setUrl] = useState("")
    const [urlHelperText, setUrlHelperText] = useState("")
    const [alwaysUseDisabled, setAlwaysUseDisabled] = useState(true)

    const MAX_WORDS_NAME = 20;
    const MAX_WORDS_DESCRIPTION = 300;
    const MAX_WORDS_TONALITY = 50;

    useEffect(() => {
        getBrandvoice();
    }, []);

    const getBrandvoice = async () => {
        setBrandvoiceLoading(true);
        try {
            const response = await API.get('settingsapi', '/brandvoice/object/' + username, {});
            setName(response.brandname || '');
            setDescription(response.description || '');
            setTonality(response.tonality || '');
            setAlwaysUseBrandVoice(response.alwaysuse || true);

            handleNameChange(response.brandname || '');
            handleDescriptionChange(response.description || '');
            handleTonalityChange(response.tonality || '');

            if (response.brandname || response.description || response.tonality) {
                setAlwaysUseDisabled(false)
            } else {
                setAlwaysUseDisabled(true)
            }

        } catch (error) {
            console.log(error);
        }
        setBrandvoiceLoading(false);
    }

    const updateBrandvoice = async () => {
        setSaveDisabled(true);
        try {
            const response = await API.put('settingsapi', '/brandvoice', {
                body: {
                    username: username,
                    brandname: name,
                    description: description,
                    tonality: tonality,
                    alwaysuse: alwaysUseBrandVoice
                }
            });
        } catch (error) {
            console.log(error);
        }
        setSaveDisabled(false);
    }

    const retrieveSummary = async () => {
        setRetrieveDisabled(true);
        setUrlLoading(true);
        try {
            const response = await API.post('websummarizer', '/summarize', {
                body: {
                    "url": url
                }
            });
        } catch (error) {
            console.log(error);
        }
        setUrlLoading(false);
        setRetrieveDisabled(false)
    }

    const handleDescriptionChange = (newText) => {
        // Count the number of words in the new text
        const words = newText.trim().split(/\s+/);
        const wordCount = words.length;

        // Update the state only if the word count is within the limit
        if (wordCount <= MAX_WORDS_DESCRIPTION) {
            setDescription(newText);
            setWordCountDescription(wordCount);
        }
        if (newText == "") {
            setWordCountDescription(0);
        } else {
            setAlwaysUseDisabled(false)
        }
    };

    const handleNameChange = (newText) => {
        const words = newText.trim().split(/\s+/);
        const wordCount = words.length;

        if (wordCount <= MAX_WORDS_NAME) {
            setName(newText);
            setWordCountName(wordCount);
        }
        if (newText == "") {
            setWordCountName(0);
        } else {
            setAlwaysUseDisabled(false)
        }
    }

    const handleTonalityChange = (newText) => {
        const words = newText.trim().split(/\s+/);
        const wordCount = words.length;

        if (wordCount <= MAX_WORDS_TONALITY) {
            setTonality(newText);
            setWordCountTonality(wordCount);
        }
        if (newText == "") {
            setWordCountTonality(0);
        } else {
            setAlwaysUseDisabled(false)
        }
    }

    const handleAlwaysUseBrandVoiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAlwaysUseBrandVoice(event.target.checked);
    }

    const handleUrlChange = (newText: string) => {
        setUrl(newText);
        // Regular expression to validate URL format
        const urlRegex = /^(https?:\/\/)?(www\.)?[\w-]+(\.[\w-]+)+([\/\w-]*)*\/?$/;

        if (urlRegex.test(newText) || newText == "" || newText == null) {
            // URL is valid, update the state
            setUrlHelperText('');
            setRetrieveDisabled(false);
        } else if (newText.length >= 3) {
            // URL is invalid, update the state with an informative message
            setUrlHelperText('Please enter a valid URL');
            setRetrieveDisabled(true);
        }
    };

    return (
        <Card className="brandvoice-card">
            <CardContent>
                <Typography variant="h5">Brand voice</Typography>
                <FormControl sx={{ pb: 4, pt: 4, width: '100%' }}>
                    <TextField
                        label={"Name of your brand/company"}
                        value={name}
                        onChange={(event) => { handleNameChange(event.target.value) }}
                        helperText={((MAX_WORDS_NAME - wordCountName)) + " words left"}
                        disabled={brandvoiceLoading}
                    />
                </FormControl>

                <FormControl sx={{ width: '100%', pb: 4 }}>
                    <TextField
                        multiline
                        rows={8}
                        label={"Short description about your brand/company"}
                        value={description}
                        onChange={(event) => { handleDescriptionChange(event.target.value) }}
                        helperText={((MAX_WORDS_DESCRIPTION - wordCountDescription)) + " words left"}
                        disabled={brandvoiceLoading}
                    />
                </FormControl>
                {/* <Stack direction="row" sx={{ pb: 4, pt: 1 }} spacing={1}>
                <Typography variant="caption" alignSelf={"center"} sx={{ maxWidth: "25%" }}>or enter your website here:</Typography>
                <FormControl sx={{ width: '65%' }}>
                    <TextField
                        label={"URL of your website or landing page"}
                        value={url}
                        onChange={(event) => { handleUrlChange(event.target.value) }}
                        helperText={urlHelperText}
                        disabled={urlLoading}
                    />
                </FormControl>
                <Button disabled={retrieveDisabled} variant="contained" color="primary" sx={{ width: "130px" }} onClick={() => retrieveSummary()}>Retrieve summary</Button>
            </Stack> */}

                <FormControl sx={{ pb: 2, width: '100%' }}>
                    <TextField
                        multiline
                        rows={4}
                        label={"Description of your tonality and style"}
                        value={tonality}
                        onChange={(event) => { handleTonalityChange(event.target.value) }}
                        helperText={((MAX_WORDS_TONALITY - wordCountTonality)) + " words left"}
                        disabled={brandvoiceLoading}
                    />
                </FormControl>

                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControlLabel
                        label="Always use brand voice"
                        labelPlacement="end"
                        control={<Checkbox />}
                        checked={alwaysUseBrandVoice}
                        onChange={handleAlwaysUseBrandVoiceChange}
                        disabled={alwaysUseDisabled}
                    />

                </Box>

                <Button disabled={saveDisabled} variant="contained" color="primary" sx={{ width: '100px' }} onClick={() => updateBrandvoice()}>Save</Button>
                {saveDisabled ? <Box alignItems={"center"}><CircularProgress size={20} sx={{ mr: 1 }} />updating brand voice...</Box> : <></>}

            </CardContent>
        </Card >
    )
}

export default BrandVoiceCard;