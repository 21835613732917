import React, { createContext, useReducer } from "react";

const initialState: iInitialState = {
    bucketRegion: "eu-central-1",
    imageUrl: "",
    imageName: "",
    bucketName: "image-to-caption-uploadstore",
    //params:
    language: "us",
    brandvoice: "none",
    brandvoiceExist: false,
    theme: "",
    setting: "",
    targetaudience: "",
    mood: "",
    formofadress: "",
    custom: "",
    emojis: true,
    hashtags: true,
    cta: true,
    ctaType: "",
    plattform: "Social Media",
    preset: "short",
    creativity: 0.90,
    length: 35,

    //custom:
    numberofhashtags: 5,
    customhashtags: "",

    videoDescription: "",
    imageDescription: "",
    creditsCost: 0,
    creditsLeft: 1,

    includeTranscription: false,
    videoAnalysisLoading: false,
    imageAnalyzing: false,

    responseLoading: false,
    getCaptionButtonDisabled: true,
    imageUploading: false,
    imageUploaded: false,
    responseCaption: "",
    notificationBarHidden: true,
    //account:
    username: "",
    stripeEmail: "",
    subscriptionStatus: "loading...",
    periodStart: "",
    periodEnd: "",
    periodDaysLeft: 0,
    plan: "",
    paymentPeriod: "monthly",
    checkingSubscriptionStatus: true,
    referalId: "",
    originCountry: "X",

    //settings:
};
interface iInitialState {
    bucketRegion: string,
    imageUrl: string,
    imageName: string,
    bucketName: string,
    //params:
    language: string,
    brandvoice: string,
    brandvoiceExist: boolean,
    theme: string,
    setting: string,
    targetaudience: string,
    mood: string,
    formofadress: string,
    custom: string,
    emojis: boolean | null,
    hashtags: boolean | null,
    cta: boolean | null,
    ctaType: string,
    plattform: string,
    preset: string,
    creativity: number,
    length: number,

    //custom:
    numberofhashtags: number,
    customhashtags: string,

    videoDescription: string,
    imageDescription: string,
    creditsCost: number,
    creditsLeft: number,

    includeTranscription: boolean,
    videoAnalysisLoading: boolean,
    imageAnalyzing: boolean,

    responseLoading: boolean,
    getCaptionButtonDisabled: boolean,
    imageUploading: boolean,
    imageUploaded: boolean,
    responseCaption: string,
    notificationBarHidden: boolean,
    //account:
    username: string,
    stripeEmail: string,
    subscriptionStatus: string,
    periodStart: string,
    periodEnd: string,
    periodDaysLeft: number,
    plan: string,
    paymentPeriod: string,
    checkingSubscriptionStatus: boolean,
    referalId: string,
    originCountry: string,

    //settings:
}
const defaultDispatch: React.Dispatch<Action> = () => initialState;

export const ImageContext = createContext(initialState);
export const ImageDispatchContext = createContext(defaultDispatch);

type Action =
    | { type: 'CHANGE_URL', imageUrl: string }
    | { type: 'CHANGE_LOADING', responseLoading: boolean }
    | { type: 'CHANGE_CAPTION', responseCaption: string }
    | { type: "CHANGE_FILENAME", imageName: string }
    | { type: "CHANGE_BUCKETNAME", bucketName: string }
    | { type: "CHANGE_BUTTON", getCaptionButtonDisabled: boolean }
    //params:
    | { type: "CHANGE_LANGUAGE", language: string }
    | { type: "CHANGE_BRANDVOICE", brandvoice: string }
    | { type: "CHANGE_BRANDVOICE_EXIST", brandvoiceExist: boolean }
    | { type: "CHANGE_THEME", theme: string }
    | { type: "CHANGE_SETTING", setting: string }
    | { type: "CHANGE_TARGETAUDIENCE", targetaudience: string }
    | { type: "CHANGE_MOOD", mood: string }
    | { type: "CHANGE_FORMOFADRESS", formofadress: string }
    | { type: "CHANGE_CUSTOM", custom: string }
    | { type: "CHANGE_EMOJIS", emojis: boolean }
    | { type: "CHANGE_HASHTAGS", hashtags: boolean }
    | { type: "CHANGE_CTA", cta: boolean }
    | { type: "CHANGE_CTATYPE", ctaType: string }
    | { type: "CHANGE_PLATTFORM", plattform: string }
    | { type: "CHANGE_PRESET", preset: string }
    | { type: "CHANGE_CREATIVITY", creativity: number }
    | { type: "CHANGE_LENGTH", length: number }

    //custom:
    | { type: "CHANGE_NUMBEROFHASHTAGS", numberofhashtags: number }
    | { type: "CHANGE_CUSTOMHASHTAGS", customhashtags: string }

    | { type: "CHANGE_VIDEO_DESCRIPTION", videoDescription: string }
    | { type: "CHANGE_IMAGE_DESCRIPTION", imageDescription: string }
    | { type: "CHANGE_CREDITS_COST", creditsCost: number }
    | { type: "CHANGE_CREDITS_LEFT", creditsLeft: number }

    | { type: "CHANGE_INCLUDE_TRANSCRIPTION", includeTranscription: boolean }
    | { type: "CHANGE_VIDEO_ANALYSIS_LOADING", videoAnalysisLoading: boolean }
    | { type: "CHANGE_IMAGE_ANALYZING", imageAnalyzing: boolean }

    | { type: "UPLOAD", imageUploading: boolean, imageUploaded: boolean }
    | { type: "RESET" }
    | { type: 'CHANGE_NOTIFICATIONBAR_HIDDEN', notificationBarHidden: boolean }
    //acount:
    | { type: 'CHANGE_USERNAME', username: string }
    | { type: 'CHANGE_STRIPE_EMAIL', stripeEmail: string }
    | { type: 'CHANGE_SUBSCRIPTION_STATUS', subscriptionStatus: string }
    | { type: 'CHANGE_PERIOD_START', periodStart: string }
    | { type: 'CHANGE_PERIOD_END', periodEnd: string }
    | { type: 'CHANGE_PERIOD_DAYS_LEFT', periodDaysLeft: number }
    | { type: 'CHANGE_PLAN', plan: string }
    | { type: 'CHANGE_CHECKING_SUBSCRIPTION_STATUS', checkingSubscriptionStatus: boolean }
    | { type: 'CHANGE_REFERAL_ID', referalId: string }
    | { type: 'CHANGE_PAYMENT_PERIOD', paymentPeriod: string }
    | { type: 'CHANGE_ORIGIN_COUNTRY', originCountry: string }
//settings:

function reducer(state: any, action: Action) {
    switch (action.type) {
        case "CHANGE_URL":
            return { ...state, imageUrl: action.imageUrl };
        case "CHANGE_LOADING":
            return { ...state, responseLoading: action.responseLoading };
        case "CHANGE_CAPTION":
            return { ...state, responseCaption: action.responseCaption };
        case "CHANGE_FILENAME":
            return { ...state, imageName: action.imageName };
        case "CHANGE_BUCKETNAME":
            return { ...state, bucketName: action.bucketName };
        case "CHANGE_BUTTON":
            return { ...state, getCaptionButtonDisabled: action.getCaptionButtonDisabled };
        //params:
        case "CHANGE_LANGUAGE":
            return { ...state, language: action.language };
        case "CHANGE_BRANDVOICE":
            return { ...state, brandvoice: action.brandvoice };
        case "CHANGE_BRANDVOICE_EXIST":
            return { ...state, brandvoiceExist: action.brandvoiceExist };
        case "CHANGE_THEME":
            return { ...state, theme: action.theme };
        case "CHANGE_SETTING":
            return { ...state, setting: action.setting };
        case "CHANGE_TARGETAUDIENCE":
            return { ...state, targetaudience: action.targetaudience };
        case "CHANGE_MOOD":
            return { ...state, mood: action.mood };
        case "CHANGE_FORMOFADRESS":
            return { ...state, formofadress: action.formofadress };
        case "CHANGE_CUSTOM":
            return { ...state, custom: action.custom };
        case "CHANGE_EMOJIS":
            return { ...state, emojis: action.emojis };
        case "CHANGE_HASHTAGS":
            return { ...state, hashtags: action.hashtags };
        case "CHANGE_CTA":
            return { ...state, cta: action.cta };
        case "CHANGE_CTATYPE":
            return { ...state, ctaType: action.ctaType };
        case "CHANGE_CREATIVITY":
            return { ...state, creativity: action.creativity };
        case "CHANGE_LENGTH":
            return { ...state, length: action.length };
        //custom:
        case "CHANGE_NUMBEROFHASHTAGS":
            return { ...state, numberofhashtags: action.numberofhashtags };
        case "CHANGE_CUSTOMHASHTAGS":
            return { ...state, customhashtags: action.customhashtags };

        case "CHANGE_VIDEO_DESCRIPTION":
            return { ...state, videoDescription: action.videoDescription };
        case "CHANGE_IMAGE_DESCRIPTION":
            return { ...state, imageDescription: action.imageDescription };
        case "CHANGE_CREDITS_COST":
            return { ...state, creditsCost: action.creditsCost };
        case "CHANGE_CREDITS_LEFT":
            return { ...state, creditsLeft: action.creditsLeft };

        case "CHANGE_INCLUDE_TRANSCRIPTION":
            return { ...state, includeTranscription: action.includeTranscription };
        case "CHANGE_VIDEO_ANALYSIS_LOADING":
            return { ...state, videoAnalysisLoading: action.videoAnalysisLoading };
        case "CHANGE_IMAGE_ANALYZING":
            return { ...state, imageAnalyzing: action.imageAnalyzing };

        case "UPLOAD":
            return { ...state, imageUploading: action.imageUploading, imageUploaded: action.imageUploaded };
        case "RESET":
            return { ...state, imageUrl: "", imageName: "", responseLoading: false, responseCaption: "", getCaptionButtonDisabled: true, imageUploading: false, imageUploaded: false };
        case "CHANGE_NOTIFICATIONBAR_HIDDEN":
            return { ...state, notificationBarHidden: action.notificationBarHidden };
        case "CHANGE_PLATTFORM":
            return { ...state, plattform: action.plattform };
        case "CHANGE_PRESET":
            return { ...state, preset: action.preset };
        //account:
        case "CHANGE_USERNAME":
            return { ...state, username: action.username };
        case "CHANGE_SUBSCRIPTION_STATUS":
            return { ...state, subscriptionStatus: action.subscriptionStatus };
        case "CHANGE_PERIOD_START":
            return { ...state, periodStart: action.periodStart };
        case "CHANGE_PERIOD_END":
            return { ...state, periodEnd: action.periodEnd };
        case "CHANGE_PERIOD_DAYS_LEFT":
            return { ...state, periodDaysLeft: action.periodDaysLeft };
        case "CHANGE_PLAN":
            return { ...state, plan: action.plan };
        case "CHANGE_PAYMENT_PERIOD":
            return { ...state, paymentPeriod: action.paymentPeriod };
        case "CHANGE_STRIPE_EMAIL":
            return { ...state, stripeEmail: action.stripeEmail };
        case "CHANGE_CHECKING_SUBSCRIPTION_STATUS":
            return { ...state, checkingSubscriptionStatus: action.checkingSubscriptionStatus };
        case "CHANGE_REFERAL_ID":
            return { ...state, referalId: action.referalId };
        case "CHANGE_ORIGIN_COUNTRY":
            return { ...state, originCountry: action.originCountry };
        //settings:
        default:
            return state;
    }
}

export function ImageProvider(props: { children: any; }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <ImageContext.Provider value={state}>
            <ImageDispatchContext.Provider value={dispatch}>
                {props.children}
            </ImageDispatchContext.Provider>
        </ImageContext.Provider>
    )
}